import * as React from 'react';

import { Routes } from '@shared/enums';
import { Text } from '@shared/ui/text';
import { Button } from '@shared/ui/buttons/Button';

import bronzeBadge from '@assets/images/badges/bronze.jpg';
import silverBadge from '@assets/images/badges/silver.jpg';
import goldBadge from '@assets/images/badges/gold.jpg';
import diamantBadge from '@assets/images/badges/diamant.jpg';
import sapphireBadge from '@assets/images/badges/sapphire.jpg';

import { useGetLegionInstructionTextByLanguage } from '@widgets/legions/hooks/useGetLegionInstructionTextByLanguage';

const LegionInstructionPage = () => {
  const t = useGetLegionInstructionTextByLanguage();

  return (
    <div className="container px-4 pt-6 pb-12 lg:pt-12">
      <h1 className="mb-4 text-xl md:text-2xl text-center">{t('legion-instruction-header')}</h1>

      <div className="mb-5">
        <Text component="h3" size="md" className="mb-2">
          {t('legion-instruction-question-1')}
        </Text>
        <Text component="p" size="xs" className="mb-2">
          {t('legion-instruction-answer-1')}
        </Text>
      </div>

      <div className="mb-5">
        <Text component="h3" size="md" className="mb-2">
          {t('legion-instruction-question-2')}
        </Text>
        <Text component="div" size="xs" className="mb-2">
          {t('legion-instruction-answer-2')}
        </Text>
      </div>

      <div className="mb-5">
        <Text component="h3" size="md" className="mb-2">
          {t('legion-instruction-question-3')}
        </Text>
        <Text component="p" size="xs" className="mb-2">
          {t('legion-instruction-answer-3')}
        </Text>
      </div>

      <div className="mb-5">
        <Text component="h3" size="md" className="mb-2">
          {t('legion-instruction-question-5')}
        </Text>
        <ul className="text-xs space-y-3">
          <li className="flex items-center space-x-[10px]">
            <img className="w-[45px] rounded-full" src={bronzeBadge} alt="Бронзова нагорода легіонера" />
            <span>- {t('legion-instruction-bronze')}.</span>
          </li>

          <li className="flex items-center space-x-[10px]">
            <img className="w-[45px] rounded-full" src={silverBadge} alt="Срібна нагорода легіонера" />
            <span>- {t('legion-instruction-silver')}.</span>
          </li>

          <li className="flex items-center space-x-[10px]">
            <img className="w-[45px] rounded-full" src={goldBadge} alt="Золота нагорода легіонера" />
            <span>- {t('legion-instruction-gold')}.</span>
          </li>

          <li className="flex items-center space-x-[10px]">
            <img className="w-[45px] rounded-full" src={sapphireBadge} alt="Сапфірова нагорода легіонера" />
            <span>- {t('legion-instruction-sapphire')}.</span>
          </li>

          <li className="flex items-center space-x-[10px]">
            <img className="w-[45px] rounded-full" src={diamantBadge} alt="Діамантова нагорода легіонера" />
            <span>- {t('legion-instruction-diamant')}.</span>
          </li>
        </ul>
      </div>

      <div className="mb-5">
        <Text component="h3" size="md" className="mb-2">
          {t('legion-instruction-question-4')}
        </Text>
        <Text component="div" size="xs" className="mb-2">
          {t('legion-instruction-answer-4')}
        </Text>
      </div>

      <div className="flex justify-center">
        <Button as="link-in" size="large" to={Routes.VolunteerCabinet}>
          {t('donate-now')}
        </Button>
      </div>
    </div>
  );
};

export default LegionInstructionPage;
